<template>
  <div>
    <div
      class="
        tw-bg-gray-100
        tw-border-b
        tw-border-gray-400
        tw-p-2
        tw-relative
        tw-text-blue-700
      "
    >
      <b>{{ $t("payslipTemplate.userVariable") }}</b>
    </div>
    <div id="varibleTable" v-show="!collapse">
      <table class="table table-hover ui-table" v-if="!readyFetchVariable">
        <thead>
          <tr>
            <th style="width: 19%">
              {{ $t("payslipTemplate.variableName") }}
            </th>
            <th>{{ $t("payslipTemplate.description") }}</th>
            <th style="width: 19%">{{ $t("payslipTemplate.type") }}</th>
          </tr>
          <tr>
            <th>
              <input
                v-model="filters.variableName"
                type="text"
                class="form-control"
                :placeholder="$t('payslipTemplate.filterVariableName')"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('payslipTemplate.filterDescription')"
                v-model="filters.description"
              />
            </th>
            <th>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('payslipTemplate.filterType')"
                v-model="filters.dataType"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(variable, index) in filterResults"
            :key="index"
            @dblclick="addFieldTemplate(variable)"
          >
            <td>{{ variable.sv_name }}</td>
            <td>{{ variable.display_name }}</td>
            <td>{{ variable.data_type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="
        tw-bg-gray-100
        tw-border-b
        tw-border-gray-400
        tw-p-2
        tw-relative
        tw-text-blue-700
        tw-my-1
      "
    >
      <b>{{ $t("payslipTemplate.pageTitle") }}</b>
    </div>
    <div
      id="tablePayrollTemplate"
      :style="{
        height: collapse ? '72vh' : '30vh',
        'max-height': collapse ? '77vh' : '32vh',
      }"
    >
      <table class="table table-hover ui-table">
        <thead>
          <tr>
            <th>{{ $t("payslipTemplate.orderId") }}</th>
            <th>{{ $t("payslipTemplate.displayName") }}</th>
            <th style="width: 10%">{{ $t("payslipTemplate.block") }}</th>
            <th style="width: 10%">{{ $t("payslipTemplate.subBlock") }}</th>
            <th style="width: 10%">{{ $t("payslipTemplate.display") }}</th>
            <th style="width: 7%" class="text-center">
              {{ $t("payslipTemplate.action") }}
            </th>
          </tr>
        </thead>
        <!-- <tbody> -->
        <draggable v-model="template_detail" tag="tbody">
          <tr v-for="(item, index) in template_detail" :key="index">
            <td valign="middle">
              {{ item.variable_name }}
            </td>
            <td style="width: 40%">
              <input
                type="text"
                class="form-control only-border-bottom"
                v-model="item.display_name"
              />
            </td>
            <td>
              <Select
                v-model="item.block_id"
                @on-change="mainBlockChange(item)"
                allowClear
                :class="{
                  'in-invalid': errors.has(
                    'template_detail.' + index + '.block_id'
                  ),
                }"
              >
                <Option
                  v-for="(block, index) in formViewDatas['blocks']"
                  :value="block.block_id"
                  :key="index"
                >
                  {{ block.block }}
                </Option>
              </Select>
              <div
                class="tw-text-red-600"
                v-if="errors.first('template_detail.' + index + '.block_id')"
              >
                {{ errors.first("template_detail." + index + ".block_id") }}
              </div>
            </td>
            <td style="width: 25%">
              <Select
                v-model="item.sub_block_id"
                @on-change="mainBlockChange(item)"
                allowClear
                :class="{
                  'is-invalid': errors.has(
                    'template_detail.' + index + '.sub_block_id'
                  ),
                }"
              >
                <Option
                  v-for="(sub, index) in formViewDatas['subBlocks'].filter(
                    (s) => s.block_id == item.block_id
                  )"
                  :key="index"
                  :value="sub.sub_block_id"
                >
                  {{ sub.sub_block }}
                </Option>
              </Select>
              <div
                class="tw-text-red-600"
                v-if="
                  errors.first('template_detail.' + index + '.sub_block_id')
                "
              >
                {{ errors.first("template_detail." + index + ".sub_block_id") }}
              </div>
            </td>
            <td>
              <Select
                v-model="item.display_side"
                :class="{
                  'is-invalid': errors.has(
                    'template_detail.' + index + '.display_side'
                  ),
                }"
              >
                <Option value="R">Right</Option>
                <Option value="L">Left</Option>
              </Select>
              <div
                class="tw-text-red-600"
                v-if="
                  errors.first('template_detail.' + index + '.display_side')
                "
              >
                {{ errors.first("template_detail." + index + ".display_side") }}
              </div>
            </td>
            <td class="text-center" valign="middle">
              <a
                href="#"
                class="mt-2"
                @click="removeRow(item)"
                v-tooltip="$t('payslipTemplate.remove')"
              >
                <i class="fas fa-minus-circle mt-2 text-danger"></i>
              </a>
            </td>
          </tr>
        </draggable>
        <!-- </tbody> -->
      </table>
    </div>
  </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "varible-list",
  props: ["value", "validation"],
  components: {
    draggable,
  },
  data() {
    return {
      errors: new Errors(),
      collapse: false,
      waiting: false,
      readyFetchVariable: false,
      template_detail: [],
      filters: {
        variableName: "",
        description: "",
        dataType: "",
      },
    };
  },
  computed: {
    ...mapState("payroll/payslipTemplate", ["formViewDatas"]),
    filterResults() {
      if (this.formViewDatas["systemVariables"]) {
        return this.formViewDatas["systemVariables"]
          .filter(
            (v) =>
              v.sv_name
                .toLowerCase()
                .indexOf(this.filters.variableName.toLowerCase()) != -1
          )
          .filter(
            (v) =>
              v.display_name
                .toLowerCase()
                .indexOf(this.filters.description.toLowerCase()) != -1
          )
          .filter(
            (v) =>
              v.data_type
                .toLowerCase()
                .indexOf(this.filters.dataType.toLowerCase()) != -1
          );
      } else return this.formViewDatas["systemVariables"];
    },
  },
  async created() {
    this.template_detail = this.value;
  },
  methods: {
    addFieldTemplate(field) {
      this.template_detail.push({
        variable_name: field.sv_name,
        display_name: field.display_name,
        block_id: "",
        sub_block_id: "",
        display_side: "",
      });
    },
    removeRow(item) {
      this.template_detail.splice(this.template_detail.indexOf(item), 1);
    },
    mainBlockChange(row) {
      if (!row.block_id) {
        row.sub_block_id = "";
      }
    },
  },
  watch: {
    template_detail: function (value) {
      this.$emit("sort", value);
    },
    value: function () {
      this.template_detail = this.value;
    },
    validation: {
      handler() {
        this.errors = new Errors(this.validation.errors);
      },
      deep: true,
    },
  },
};
</script>

<style lang="css" scoped>
#varibleTable {
  max-height: 300px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#tablePayrollTemplate {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 3px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: rgb(194, 194, 194);
}
.table > thead > tr > th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  padding: 7px;
  border-bottom: none !important;
}
#varibleTable .table > tbody > tr > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border: none !important;
  cursor: pointer;
}
#tablePayrollTemplate .table > tbody > tr > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  border: none !important;
  cursor: grab;
}
.only-border-bottom {
  border-top: none;
  border-left: none;
  border-right: none;
}
</style>
