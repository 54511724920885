<template>
  <div>
    <ts-page-title
      :title="$t('payslipTemplate.pageTitle')"
      :breadcrumb="[
        { text: $t('home'), href: '/' },
        {
          text: $t('payslipTemplate.pageTitle'),
          active: true,
        },
      ]"
    />
    <ts-panel>
      <ts-panel-wrapper class="tw-flex tw-items-center tw-justify-between">
        <Button type="info" ghost @click="addNew">{{
          $t("addNew")
        }}</Button>
        <div class="tw-justify-end tw-space-x-2">
          <Input
            search
            v-model="search"
            :placeholder="$t('payslipTemplate.search')"
            :style="width"
            @on-focus="() => (width = 'width: 500px')"
            @on-blur="() => (width = 'width: 300px')"
          />
        </div>
      </ts-panel-wrapper>
      <Table
        :columns="columns"
        :data="resources"
        size="small"
        :loading="loading"
        max-height="700"
        stripe
      >
        <template slot-scope="{ row }" slot="shift_name">
          {{
            row.shift_working_hours ? row.shift_working_hours.shift_name : ""
          }}
        </template>
        <template slot-scope="{ row }" slot="keyword">
          {{ row.shift_working_hours ? row.shift_working_hours.keyword : "" }}
        </template>
        <template slot-scope="{ row }" slot="ot_name">
          {{ row.overtime_item ? row.overtime_item.ot_name : "" }}
        </template>

        <template slot-scope="{ row }" slot="action">
          <a
            href="#"
            class="text-primary ml-2"
            @click.prevent="onEdit(row)"
            v-tooltip="$t('edit')"
          >
            <Icon type="ios-create" size="20" />
          </a>
        </template>
      </Table>
      <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
        <ts-i-pagination
          v-model="pagination"
          @navigate="fetchData"
        ></ts-i-pagination>
      </div>
      <Modal
        v-model="showForm"
        draggable
        sticky
        scrollable
        :mask="false"
        :footer-hide="true"
        :closable="true"
        :z-index="1000"
        width="80%"
        :title="$t('payslipTemplate.pageTitle')"
        :afterClose="clearEdit"
      >
        <from-action
          ref="form_action"
          @cancel="clearEdit"
          @fetchData="fetchData"
        />
      </Modal>
    </ts-panel>
  </div>
</template>
<script>
import FromAction from "./form.vue";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "payroll-cycle",
  components: {
    FromAction,
  },
  data() {
    return {
      loading: false,
      showForm: false,
      width: "width: 300px",
    };
  },
  computed: {
    ...mapState("payroll/payslipTemplate", ["resources", "pagination"]),
    search: {
      get() {
        return this.$store.state.payroll.payslipTemplate.search;
      },
      set(newValue) {
        this.$store.commit("payroll/payslipTemplate/SET_SEARCH", newValue);
        this.$store.commit("payroll/payslipTemplate/RESET_CURRENT_PAGE");
      },
    },
    columns() {
      return [
        {
          title: this.$t("payslipTemplate.templateName"),
          key: "template_name",
          sortable: true,
          minWidth: 150,
        },
        {
          title: this.$t("payslipTemplate.createdDate"),
          key: "created_date",
          sortable: true,
          minWidth: 150,
        },
        {
          title: this.$t("actions"),
          key: "action",
          slot: "action",
          align: "center",
          className: "tw-space-x-2",
          width: 90,
        },
      ];
    },
  },
  methods: {
    addNew() {
      this.$store.commit("payroll/payslipTemplate/SET_EDIT_DATA", {});
      this.$refs.form_action.clearInput();
      this.showForm = true;
      this.$refs.form_action.fetchFormViewModel({
        params: {},
        index: undefined,
      });
    },
    fetchData(attributes) {
      this.loading = true;
      this.$store
        .dispatch("payroll/payslipTemplate/fetch", attributes)
        .catch((error) => {
          this.loading = false;
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit(record) {
      this.showForm = true;
      this.$refs.form_action.clearInput();
      this.$store.commit("payroll/payslipTemplate/SET_EDIT_DATA", record);
      this.$refs.form_action.setEditData();
      this.$refs.form_action.fetchFormViewModel({
        params: {},
        index: undefined,
      });
    },
    clearEdit() {
      this.showForm = false;
      this.$refs.form_action.clearInput();
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "PAYSLIP TEMPLATE",
        desc: not.text,
      });
    },
  },
  watch: {
    search: debounce(function (search) {
      this.fetchData({ search });
    }, 500),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData();
    });
  },
};
</script>
